import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Llm } from 'src/app/enums/llm';
import { ChatApp } from 'src/app/models/chat-app';
import { ChatMessage } from 'src/app/models/chat-message';
import { ChatStreamService } from 'src/app/services/chat/chat-stream.service';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  private _model: Llm = Llm.CODE_LLAMA_34B_002_FUSED;
  @Output() sidebarToggle = new EventEmitter<boolean>();
  @Input() config?: ChatApp;
  @Input() title = 'Next Code';
  @Input() showWelcome = true;
  @Input() nextId = 'default';
  public _sidebarClose = false;
  @Input() set model(value: any) {
    if (!value) {
      return;
    }
    this._model = value;
    this.updateModel(value);
  }

  @Input()
  set sidebarClose(value: boolean) {
    this._sidebarClose = value;
  }

  @Output() afterMessage: EventEmitter<ChatMessage> =
    new EventEmitter<ChatMessage>();

  finalConfig!: ChatApp;
  appDefault!: ChatApp;
  messages: ChatMessage[] = [];

  constructor(
    private chatService: ChatStreamService,
    private readonly keycloak: KeycloakService
  ) {
    keycloak.keycloakEvents$.subscribe({
      next(event) {
        if (event.type === KeycloakEventType.OnTokenExpired) {
          keycloak.updateToken().then((response) => {
            if (response) {
              keycloak.getToken().then((token) => {
                chatService.setBearer(token);
              });
            }
          });
        }
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async ngOnInit() {
    this.appDefault = {
      title: 'Next Code',
      showWelcome: true,
      welcome: {
        title: '👋 ¡Hola!',
        subtitle: 'Bienvenido a Next Code',
        content:
          'Next Code está diseñado para brindarte apoyo en dudas y consultas de código de forma rápida. Está en fase beta, por lo que puedes encontrarte con inconvenientes mientras lo utilizas.'
      },
      placeholder: 'Escribe tu consulta',
      showVersion: true,
      showRefresh: true,
      refresh: {
        label: 'Nueva conversación',
        icon: 'refresh'
      },
      close: {
        label: 'Cerrar sesión',
        icon: 'close'
      },
      source: 'web',
      model: this._model
    };

    if (!this._model && typeof this._model === 'string') {
      this.updateModel(this._model);
    } else {
      this.updateModel('CODE_LLAMA_34B_002_FUSED');
    }

    if (this.nextId !== undefined) {
      this.chatService.setNextId(this.nextId);
    } else {
      console.error('errror next es undefined');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  afterWriteMessage(message: ChatMessage) {
    this.afterMessage.emit(message);
  }

  onButtonClick() {
    this.sidebarToggle.emit();
  }

  // updateModel(modelKey: string) {
  //   console.log('updateModel', modelKey);
  //   this.model = Llm[modelKey as keyof typeof Llm];
  //   this.finalConfig.model = this.model;
  // }

  updateModel(modelKey: string) {
    console.log('updateModel', modelKey);
    this._model = Llm[modelKey as keyof typeof Llm];

    let welcome = this.appDefault.welcome;
    let refresh = this.appDefault.refresh;
    if (this.config != undefined && this.config.welcome) {
      welcome = {
        ...this.appDefault.welcome,
        ...this.config.welcome
      };
    }
    if (this.config != undefined && this.config.refresh) {
      refresh = {
        ...this.appDefault.refresh,
        ...this.config.refresh
      };
    }
    this.finalConfig = {
      ...this.appDefault,
      ...this.config,
      model: this._model
    };
    this.finalConfig.welcome = welcome;
    this.finalConfig.refresh = refresh;

    this.chatService.model$.emit(this.finalConfig.model);
  }
}
