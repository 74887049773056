<div
  class="d-flex flex-column flex-shrink-0 p-3"
  [ngStyle]="{ 'min-height': minHeight, 'max-height': maxHeight }"
  style="width: 280px"
>
  <div class="d-flex pl-5">
    <div
      id="head-box"
      class="pl-3 flex-grow-1">
      <a
        class="
          d-flex
          align-items-center
          mb-3
          mb-md-0
          me-md-auto
          text-white
          ml-auto
          text-decoration-none"
          (click)="newConversacion()"
      >
        <img
          id="imagen-sanwich"
          style=""
          src="assets/img/logo.svg"
          width="auto"
          height="50"
          alt="logo"
        />
      </a>
    </div>
  </div>

  <mat-divider></mat-divider>
  <!-- <div class="text-right">
    <button
      type="button"
      (click)="newConversacion()"
      class="btn btn-dark border-light border-01"
    >
      <i class="fa-solid fa-plus"></i>Nuevo chat
    </button>
  </div> -->

  <mat-selection-list #shoes [multiple]="false">
    <div *ngFor="let item of conversaciones">
      <mat-list-option [value]="item">
        {{ item.titulo }}
      </mat-list-option>
      <mat-divider></mat-divider>
    </div>
  </mat-selection-list>
</div>
