<h2 mat-dialog-title>
  Ayúdanos a mejorar
</h2>
<mat-dialog-content class="mat-typography">
  <h3>Envíanos tu sugerencia de código a continuación</h3>
  <ngs-code-editor
    id="dialog-code-editor"
    [theme]="theme"
    [codeModel]="codeModel"
    [options]="options"
    (valueChanged)="onCodeChanged($event)">
  </ngs-code-editor>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="warn">
    Cancelar
  </button>
  <button mat-raised-button [mat-dialog-close]="true" color="primary">
    Enviar
  </button>
</mat-dialog-actions>
