<cdk-virtual-scroll-viewport
  #scroller
  itemSize="120"
  [class]="contentStyle"
  minBufferPx="200"
  maxBufferPx="400"
>
  <div class="welcome" *ngIf="appConfig.showWelcome">
    <h1>
      {{ appConfig.welcome?.title }}
    </h1>
    <h2>
      {{ appConfig.welcome?.subtitle }}
    </h2>
    <p class="comment">
      {{ appConfig.welcome?.content }}
    </p>
  </div>

  <div class="container">
    <div class="chatfpt">
      <ng-container *cdkVirtualFor="let item of messages; let odd = odd">
        <div
          *ngIf="shouldDisplayItem(item)"
          [attr.data-user]="item.role"
          [appChatRoleStyle]="item.role"
        >
          <markdown
            lineHighlight
            clipboard
            [data]="item.content.replace(endOfStringRegExp, '')"
          ></markdown>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="grid-center evaluation-box" *ngIf="alreadyUsed">
  <!--<div class="grid-center evaluation-box">-->
    <div class="col-12">
      <div class="evaluation-question">¿Esta respuesta te funcionó?</div>
    </div>
    <div class="col grid align-center evaluation-buttons">
      <div class="col">
        <button
          [disabled]="disableFeedback"
          mat-stroked-button
          color="primary"
          (click)="sendFeedback(FeedbackResponse.GOOD)"
        >
          👍 Si
        </button>
      </div>
      <div class="col">
        <button
          [disabled]="disableFeedback"
          mat-stroked-button
          color="primary"
          (click)="onNegativeFeedback(FeedbackResponse.REGULAR, messages[messages.length-1])"
        >
          ≈ Regular
        </button>
      </div>
      <div class="col">
        <button
          [disabled]="disableFeedback"
          mat-stroked-button
          color="warn"
          (click)="onNegativeFeedback(FeedbackResponse.BAD, messages[messages.length-1])"
        >
          👎 No
        </button>
      </div>
    </div>
  </div>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</cdk-virtual-scroll-viewport>
