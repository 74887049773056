import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Message } from '../../models/message';
import { HttpClient } from '@angular/common/http';
import { ChatAppService } from '../chat-app/chat-app.service';
import { ChatResponse } from '../../models/chat-response';
import { ChatResponseStream } from '../../models/chat-response-stream';
import { ChatRole } from 'src/app/enums/chat-role';
import { Llm } from 'src/app/enums/llm';
import { Observer, of } from 'rxjs';
import { ChatMessage } from 'src/app/models/chat-message';
import { FeedbackResponse } from 'src/app/enums/feedback-response';
import { Chat } from 'src/app/models/chat';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatStreamService {
  private model = Llm.CODE_LLAMA_34B_002_FUSED;
  conversationId = '';

  message$ = new BehaviorSubject<ChatMessage>({
    role: ChatRole.ASSISTANT,
    content: ''
  });
  chat: Chat = {
    model: this.model,
    messages: [],
    stream: true
  } as Chat;
  message = {} as Message;
  chatHistory$ = new BehaviorSubject<ChatMessage[]>([]);
  response$ = new BehaviorSubject<ChatResponseStream>({
    content: '',
    id_complition: ''
  });

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showFeedbackBox$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  refresh$: EventEmitter<boolean> = new EventEmitter<boolean>();
  model$: EventEmitter<Llm> = new EventEmitter<Llm>();
  sending$: EventEmitter<boolean> = new EventEmitter<boolean>();
  nextId = '';
  initMessage = {
    role: ChatRole.SYSTEM,
    content: 'Eres "Next Code", un chatbot inteligente creado exclusivamente para asesorar y resolver desafíos de programación en el área de sistemas de "Regional", una institución bancaria líder. Armado con un amplio dominio en Java, Python, Kotlin, Sybase, PostgreSQL y Swift, tu misión es generar código limpio, eficiente y seguro. Te destacas por tu capacidad para proporcionar soluciones tecnológicas que no solo se integran perfectamente con los sistemas existentes, sino que también promueven la innovación y cumplen con las regulaciones bancarias. Eres el pilar tecnológico sobre el que "Regional" construye su futuro, combinando fiabilidad, innovación y precisión en cada tarea que realizas.'
  } as ChatMessage;
  bearerToken = '';

  constructor(
    private http: HttpClient,
    private chatAppService: ChatAppService
  ) {
    this.model$.subscribe((model: Llm) => {
      this.model = model;
      this.chat.model = this.model;
    });
    this.chatAppService.refresh$.subscribe((refresh: boolean) => {
      this.refresh$.emit(refresh);
    });
    this.chatHistory$.subscribe((messages: ChatMessage[]) => {
      this.chat.messages = [...messages];
    });
  }

  /**
   * Establece el valor del próximo ID.
   *
   * @param {string} nextId - El próximo ID a establecer.
   */
  setNextId(nextId: string): void {
    this.nextId = nextId;
    this.chatAppService.setNextId(this.nextId);
    this.conversationId = this.chatAppService.getConversationalId();
  }

  /**
   * Establece el valor del token de autenticación (Bearer token) para el servicio de chat.
   *
   * @param {string} bearer - El token de autenticación a establecer.
   */
  setBearer(bearer: string): void {
    this.bearerToken = bearer;
  }

  /**
   * Actualiza la conversación actual en el servicio de chat.
   *
   * Esta función puede ser utilizada cuando se necesita obtener los mensajes más recientes
   * de la conversación o cuando se realiza alguna acción que modifica la conversación,
   * como enviar un nuevo mensaje o eliminar un mensaje existente.
   */
  refreshConversation(): void {
    this.conversationId = this.chatAppService.refreshConversationalId();
    this.chat.messages = [this.initMessage, ...this.getConversaciones()];
    // this.chat.messages = this.getConversaciones();
  }

  /**
   * Envía un mensaje al servicio de chat.
   *
   * @param {string} content - El contenido del mensaje a enviar.
   * @returns {Observable<ChatResponseStream>} Un Observable que emite la respuesta del chat.
   */
  send(content: string): Observable<ChatResponseStream> {
    this.message = {
      role: ChatRole.USER,
      content
    } as Message;
    this.chat.messages.push(this.message);
    this.chat.model = this.model;
    this.write(content, ChatRole.USER);
    this.showFeedbackBox$.next(false);
    this.sending$.emit(true);
    this.chatAppService.updateExpires();

    return new Observable<ChatResponseStream>((observer) => {
      const controller = new AbortController();

      const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.chat),
        signal: controller.signal
      };

      let url = '/api/azure/v1/chat/completions'; // Modelos code llama con fastchat

      if ([Llm.OPENAI_GPT_3_5, Llm.OPENAI_GPT_4].includes(this.model)) {
        // Modelos de openai
        requestOptions.headers = {
          ...requestOptions.headers,
          'api-key': environment.openai_api_key
        };
        url = `/api/openai/openai/deployments/${this.model}/chat/completions?api-version=${environment.openai_api_version}`;
      } else if ([Llm.MISTRAL_8X_7B_INSTRUCT].includes(this.model)) {
        url = `/api/mistral/v1/chat/completions`;
      }

      const handleResponse = async (response: Response) =>
        this.processResponse(response, observer);

      fetch(url, requestOptions)
        .then(handleResponse)
        .catch((error) => {
          console.log(error);
          observer.error(error);
          this.loading$.next(false);
        });

      return () => controller.abort();
    });
  }

  /**
   * Procesa la respuesta del servicio de chat.
   *
   * Esta función privada se utiliza para manejar la lógica de procesamiento de las respuestas
   * que se reciben del servicio de chat. Esto puede incluir la actualización del estado interno
   * del servicio, la emisión de eventos basados en la respuesta, etc.
   *
   * Como es una función asíncrona, puede realizar operaciones que requieren esperar a que se
   * complete una Promesa, como las solicitudes de red o las operaciones de lectura/escritura de archivos.
   *
   * @param {ChatResponseStream} response - La respuesta recibida del servicio de chat.
   */
  private async processResponse(
    response: Response,
    observer: Observer<ChatResponseStream>
  ): Promise<void> {
    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('Failed to read response');
    }
    const decoder = new TextDecoder('utf-8');

    if (!response.ok) {
      this.handleErrorResponse(reader, observer);
      return;
    }

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      if (value) {
        this.processResponseLines(decoder.decode(value), observer);
      }
    }

    observer.complete();
    reader.releaseLock();
  }

  /**
   * Procesa las líneas de respuesta del servicio de chat.
   *
   * Esta función privada se utiliza para manejar la lógica de procesamiento de las líneas de respuesta
   * que se reciben del servicio de chat. Esto puede incluir la actualización del estado interno
   * del servicio, la emisión de eventos basados en la respuesta, etc.
   *
   * Como es una función asíncrona, puede realizar operaciones que requieren esperar a que se
   * complete una Promesa, como las solicitudes de red o las operaciones de lectura/escritura de archivos.
   *
   * @param {string[]} lines - Las líneas de respuesta recibidas del servicio de chat.
   */
  private processResponseLines(
    lines: string,
    observer: Observer<ChatResponseStream>
  ): void {
    for (const line of lines.split('\n')) {
      if (!line.startsWith('data: ')) {
        continue;
      }
      if (line.includes('[DONE]')) {
        this.showFeedbackBox$.next(true);
        this.loading$.next(false);
        return;
      }

      const newLine = line.slice(6);

      try {
        const parser = JSON.parse(newLine);
        if (
          !(
            parser.choices[0] &&
            parser.choices[0].delta &&
            parser.choices[0].delta.content
          )
        ) {
          continue;
        }

        const data = {
          id_complition: parser.id,
          content: parser.choices[0].delta.content
        } as ChatResponseStream;
        observer.next(data);
      } catch (e) {
        console.log('Error parsing line:', newLine);
      }
    }
  }

  /**
   * Maneja las respuestas de error del servicio de chat.
   *
   * Esta función privada se utiliza para manejar la lógica de procesamiento de las respuestas de error
   * que se reciben del servicio de chat. Esto puede incluir la actualización del estado interno
   * del servicio, la emisión de eventos basados en la respuesta de error, etc.
   *
   * Como es una función asíncrona, puede realizar operaciones que requieren esperar a que se
   * complete una Promesa, como las solicitudes de red o las operaciones de lectura/escritura de archivos.
   *
   * @param {Error} error - El error recibido del servicio de chat.
   * @returns {void} No devuelve nada.
   */
  private handleErrorResponse(
    reader: ReadableStreamDefaultReader<Uint8Array>,
    observer: Observer<ChatResponseStream>
  ): void {
    this.loading$.next(false);
    reader.read().then(({ done, value }) => {
      if (done || !value) {
        observer.error({
          detail: 'Ha ocurrido un error'
        });
        return;
      }

      const lines = new TextDecoder('utf-8').decode(value).split('\n');
      if (lines.length <= 0) {
        return;
      }

      try {
        const error = JSON.parse(lines[0]);
        observer.error(error);
      } catch (e) {
        observer.error({ detail: lines[0] });
      }
    });
  }

  /**
   * Envía una valoración al servicio de chat.
   *
   * @param {number} review - La valoración a enviar. Debe ser un número que represente la valoración del usuario.
   * @returns {Observable<ChatResponse>} Un Observable que emite la respuesta del chat.
   */
  feedback(review: FeedbackResponse): Observable<ChatResponse> {
    this.chatAppService.updateExpires();
    // return this.http.get<ChatResponse>(
    //   `chat/chatcontext/feedback/${review}/id/1`
    // );
    const mockResponse: ChatResponse = {
      content: 'Ok',
      idMessage: '1',
      content_type: 'text/plain'
    };

    return of(mockResponse);
  }

  getConversaciones(): Message[] {
    return JSON.parse(localStorage.getItem('conversations') || '[]');
  }

  /**
   * Escribe un mensaje en el servicio de chat.
   *
   * @param {string} message - El mensaje a escribir en el chat.
   * @param {string} role - El rol del usuario que escribe el mensaje. Puede ser 'user' o 'bot'.
   */
  write(content: string, role: ChatRole): void {
    const message: ChatMessage = {
      role: role,
      content: content
    };

    this.message$.next(message);
  }
}
