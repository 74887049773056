import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { CodeModel } from '@ngstack/code-editor';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NegativeFeedbackDialogData } from '../../../../models/negative-feedback-dialog-data';
import { CodeData } from '../../../../models/code-data';

@Component({
  selector: 'app-code-dialog',
  templateUrl: './code-dialog.component.html',
  styleUrls: ['./code-dialog.component.scss']
})
export class CodeDialogComponent {
  @Output() code: EventEmitter<CodeData> = new EventEmitter<CodeData>();
  currentCode = '';
  currentLanguage = '';
  theme = 'vs-dark';

  languages = [
    {
      label: 'Swift',
      value: 'swift'
    },
    {
      label: 'Kotlin',
      value: 'kotlin'
    }
  ];

  codeModel: CodeModel = {
    language: 'typescript',
    uri: 'main.ts',
    value: ''
  };

  options = {
    contextmenu: true,
    minimap: {
      enabled: false
    }
  };

  onCodeChanged(value: string) {
    this.currentCode = value;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CodeData
  ) {
    this.codeModel.value = data.code;
    this.currentCode = data.code;
    this.currentLanguage = data.language;
  }

  saveCodeToChat(): void {
    this.code.emit({
      code: this.currentCode,
      language: this.currentLanguage
    });
  }

  deleteCode(): void {
    this.codeModel.value = '';
    this.code.emit({
      code: '',
      language: ''
    });
  }
}
