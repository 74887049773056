<mat-card class="full-width mat-elevation-z24 chat-app-controls">
  <mat-card-content class="message-box">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <form class="grid">
      <div class="col">
        <mat-form-field appearance="fill" id="text-box">
          <mat-label>
            {{ appConfig.placeholder }}
          </mat-label>
          <textarea
            #messageBox
            id="messageBox"
            [(ngModel)]="message"
            name="message"
            (keydown.enter)="sendMessage($event)"
            autofocus
            matInput
          >
          </textarea>
        </mat-form-field>
      </div>
      <div class="grid grid-center">
        <div class="col">
          <button
            mat-icon-button
            matTooltip="Añadir código"
            [matBadge]="codeBadge"
            [matBadgeHidden]="codeBadgeHidden"
            matBadgeSize="large"
            (click)="openAddCodeDialog()"
          >
            <mat-icon>code_blocks</mat-icon>
          </button>
        </div>
        <div class="col">
          <button
            mat-icon-button
            matTooltip="Enviar"
            (click)="sendMessage($event)"
          >
            <mat-icon>send</mat-icon>
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
