import { Component, Inject, OnInit } from '@angular/core';
import { CodeModel } from '@ngstack/code-editor';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NegativeFeedbackDialogData } from '../../../../models/negative-feedback-dialog-data';

@Component({
  selector: 'app-negative-feedback-dialog',
  templateUrl: './negative-feedback-dialog.component.html',
  styleUrls: ['./negative-feedback-dialog.component.scss']
})
export class NegativeFeedbackDialogComponent implements OnInit {
  theme = 'vs-dark';

  codeModel: CodeModel = {
    language: 'typescript',
    uri: 'main.ts',
    value: '',
    dependencies: [
      '@angular/animations',
      '@angular/cdk',
      '@angular/common',
      '@angular/compiler',
      '@angular/core',
      '@angular/forms',
      '@angular/router'
    ]
  };

  options = {
    contextmenu: true,
    minimap: {
      enabled: true
    }
  };

  onCodeChanged(value: unknown) {
    console.log('CODE', value);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NegativeFeedbackDialogData
  ) {
    this.codeModel.value = data.code;
  }

  ngOnInit(): void {
  }

}
