import { EventEmitter, Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { ChatStoreService } from '../chat-store/chat-store.service';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class ChatAppService {
  protected conversationId = '';
  protected model!: string;
  protected source!: string;
  private today = dayjs();
  private expires = 120;
  private format = 'YYYY-MM-DD HH:mm:ss';

  refresh$: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private chatStoreService: ChatStoreService) {}

  /**
   * Inicializa la conversación en el chat.
   *
   * Esta función realiza las siguientes operaciones:
   * 1. Obtiene el ID de la conversación actual del almacenamiento.
   * 2. Comprueba si la conversación actual ha expirado.
   * 3. Si la conversación no ha expirado, se utiliza el ID de la conversación actual.
   * 4. Si la conversación ha expirado, se genera un nuevo ID de conversación.
   * 5. Almacena el ID de la conversación y la fecha de expiración en el almacenamiento.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  initialize() {
    this.today = dayjs();
    let control = false;
    let current = '';
    const conversationId = this.chatStoreService.get();

    if (conversationId) {
      const expires = dayjs(conversationId.expires, this.format);
      const now = dayjs();
      control = expires.isAfter(now);
      current = conversationId.conversationalId;
    }

    if (control) {
      this.conversationId = current;
    } else {
      this.conversationId = uuidv4();
    }

    this.chatStoreService.set({
      conversationalId: this.conversationId,
      expires: this.today.add(this.expires, 'm').format(this.format)
    });
  }

  /**
   * Establece el siguiente ID de la conversación y luego inicializa la conversación.
   *
   * @param {string} nextId - El siguiente ID de la conversación a establecer.
   *
   * Esta función realiza las siguientes operaciones:
   * 1. Llama a `chatStoreService.setNextID` para establecer el siguiente ID de la conversación.
   * 2. Llama a `initialize` para inicializar la conversación.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setNextId(nextId: string) {
    this.chatStoreService.setNextID(nextId);
    this.initialize();
  }

  /**
   * Actualiza la fecha de expiración de la conversación actual.
   *
   * Esta función realiza las siguientes operaciones:
   * 1. Obtiene la fecha y hora actuales.
   * 2. Calcula la nueva fecha de expiración sumando el tiempo de expiración al tiempo actual.
   * 3. Llama a `chatStoreService.set` para actualizar el ID de la conversación y la fecha de expiración en el almacenamiento.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateExpires() {
    this.today = dayjs();
    this.chatStoreService.set({
      conversationalId: this.conversationId,
      expires: this.today.add(this.expires, 'm').format(this.format)
    });
  }

  /**
   * Obtiene el ID de la conversación actual.
   *
   * @returns {string} El ID de la conversación actual.
   */
  getConversationalId(): string {
    return this.conversationId;
  }

  /**
   * Refresca el ID de la conversación actual.
   *
   * Esta función realiza las siguientes operaciones:
   * 1. Obtiene la fecha y hora actuales.
   * 2. Genera un nuevo ID de conversación.
   * 3. Emite un evento de refresco.
   * 4. Llama a `chatStoreService.set` para actualizar el ID de la conversación y la fecha de expiración en el almacenamiento.
   *
   * @returns {string} El nuevo ID de la conversación.
   */
  refreshConversationalId(): string {
    this.today = dayjs();
    this.conversationId = uuidv4();
    this.refresh$.emit(true);
    this.chatStoreService.set({
      conversationalId: this.conversationId,
      expires: this.today.add(this.expires, 'm').format(this.format)
    });
    return this.conversationId;
  }

  /**
   * Obtiene el ID del equipo del cliente.
   *
   * Esta función realiza las siguientes operaciones:
   * 1. Intenta recuperar el ID del equipo del almacenamiento local.
   * 2. Si no se encuentra ningún ID, genera un nuevo ID y lo almacena en el almacenamiento local.
   *
   * @returns {string} El ID del equipo del cliente.
   */
  getIdEquipoCliente(): string {
    let equipoId = localStorage.getItem('equipoIdClienteHeyChat') ?? '';
    console.log('Identificador recuperado del almacenamiento local:', equipoId);
    if (equipoId == '') {
      equipoId = uuidv4();
      localStorage.setItem('equipoIdClienteHeyChat', equipoId);
    }
    return equipoId;
  }
}
