import {
  Component,
  Input,
  Output,
  OnInit,
  SkipSelf,
  EventEmitter,
  ViewChild,
  TemplateRef
} from '@angular/core';
import { ChatApp } from 'src/app/models/chat-app';
import { ChatStreamService } from 'src/app/services/chat/chat-stream.service';
import { KeycloakService } from 'keycloak-angular';
import { Llm } from '../../../enums/llm';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @Input() appConfig!: ChatApp;
  @Output() sidebarToggle = new EventEmitter<boolean>();
  @Output() modelChange = new EventEmitter<Llm>();
  appVersion: string;
  refreshLabel: string;
  refreshIcon: string;
  closeSesionLabel: string;
  closeSesionIcon: string;
  isSidebarOpen = false;
  models = Object.keys(Llm);
  @Output() modelSelected = new EventEmitter<string>();
  @ViewChild('modelSelectorTemplate') modelSelectorTemplate!: TemplateRef<any>;
  selectedModel!: string;

  @Input()
  set sidebarClose(value: boolean) {
    this.isSidebarOpen = value;
  }

  constructor(
    @SkipSelf() private chatService: ChatStreamService,
    private readonly keycloak: KeycloakService,
    private bottomSheet: MatBottomSheet
  ) {
    this.appVersion = '0.0.1';
    this.refreshIcon = '';
    this.refreshLabel = '';
    this.closeSesionLabel = '';
    this.closeSesionIcon = '';
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ngOnInit() {
    this.refreshLabel = this.appConfig.refresh?.label || '';
    this.refreshIcon = this.appConfig.refresh?.icon || '';
    this.closeSesionLabel = this.appConfig.close?.label || '';
    this.closeSesionIcon = this.appConfig.close?.icon || '';
    this.selectedModel = 'CODE_LLAMA_34B_002_FUSED'
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  doRefreshConversation() {
    this.chatService.refreshConversation();
  }

  logout() {
    this.keycloak.logout();
  }

  onButtonClick() {
    this.sidebarToggle.emit();
  }

  openModelSelect() {
    const bottomSheetRef = this.bottomSheet.open(this.modelSelectorTemplate, {
      data: { selectedModel: this.appConfig.model }
    });

    bottomSheetRef.afterDismissed().subscribe((newModel) => {
      if (newModel) {
        this.appConfig.model = newModel;
        this.modelChange.emit(this.appConfig.model);
      }
    });
  }

  selectModel(modelKey: string, model: Llm, bottomSheetRef: MatBottomSheetRef) {
    console.log('models', model);
    this.selectedModel = modelKey;
    this.doRefreshConversation();
    bottomSheetRef.dismiss(model);
  }

  modelValue(modelKey: string): Llm {
    console.log('modelKey', modelKey);
    this.modelSelected.emit(modelKey);
    return Llm[modelKey as keyof typeof Llm];
  }
}