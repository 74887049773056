<mat-dialog-content class="mat-typography">
  <form class="grid">
    <mat-form-field appearance="fill">
      <mat-label>Lenguaje de programación</mat-label>
      <mat-select [(ngModel)]="currentLanguage" name="language">
        <mat-option *ngFor="let lang of languages" [value]="lang.value">
          {{lang.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <ngs-code-editor
    id="dialog-code-editor-add"
    [theme]="theme"
    [codeModel]="codeModel"
    [options]="options"
    (valueChanged)="onCodeChanged($event)"
  >
  </ngs-code-editor>
</mat-dialog-content>
<mat-dialog-actions class="grid">
  <div class="col">
    <button mat-button mat-dialog-close (click)="deleteCode()">
      Borrar
    </button>
  </div>
  <div class="col-8 grid-right">
    <button mat-button mat-dialog-close color="warn">
      Cancelar
    </button>
    <button mat-raised-button mat-dialog-close (click)="saveCodeToChat()" color="primary">
      Guardar
    </button>
  </div>
</mat-dialog-actions>
