<mat-toolbar color="primary" class="mat-elevation-z24">
  <div *ngIf="!this.isSidebarOpen" id="sidebar-section">
    <button mat-icon-button aria-label="Conversación" class="corner-button-2" (click)="onButtonClick()">
      <mat-icon>menu</mat-icon>
    </button>
    <div>
      {{ appConfig.title }}
    </div>
    <div class="app-version" *ngIf="appConfig.showVersion">
      v{{ appVersion }}
    </div>
  </div>

  <div id="action-section">

    <button mat-icon-button (click)="openModelSelect()" matTooltip="Seleccionar modelo">
      <mat-icon>smart_toy</mat-icon>
    </button>

    <button [matTooltip]="refreshLabel" mat-icon-button (click)="doRefreshConversation()">
      <mat-icon>{{ refreshIcon }}</mat-icon>
    </button>

    <button [matTooltip]="closeSesionLabel" mat-icon-button (click)="logout()">
      <mat-icon>{{ closeSesionIcon }}</mat-icon>
    </button>
  </div>

  <ng-template #modelSelectorTemplate let-bottomSheetRef="bottomSheetRef">
    <div class="model-selector">
      <h2>Selecciona un modelo</h2>
      <mat-nav-list>
        <a mat-list-item *ngFor="let model of models" [ngClass]="{'selected': model === selectedModel}"
          (click)="selectModel(model, modelValue(model), bottomSheetRef)">
          <span mat-line>{{model}}</span>
        </a>
      </mat-nav-list>
    </div>
  </ng-template>
</mat-toolbar>