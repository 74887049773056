import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { ChatRole } from 'src/app/enums/chat-role';
import { FeedbackMessages } from 'src/app/enums/feedback-messages';
import { FeedbackResponse } from 'src/app/enums/feedback-response';
import { ChatApp } from 'src/app/models/chat-app';
import { ChatMessage } from 'src/app/models/chat-message';
import { ChatResponse } from 'src/app/models/chat-response';
import { ChatStreamService } from 'src/app/services/chat/chat-stream.service';
import { MatDialog } from '@angular/material/dialog';
import { NegativeFeedbackDialogComponent } from '../dialogs/negative-feedback-dialog/negative-feedback-dialog.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  @ViewChild('scroller') scroller!: CdkVirtualScrollViewport;
  @Input() appConfig!: ChatApp;
  @Input() refresh = false;
  @Output() afterWriteMessage: EventEmitter<ChatMessage> =
    new EventEmitter<ChatMessage>();

  markdownContent = '';
  renderedMarkdown = '';

  endOfStringRegExp = /\[done\]/gi;
  initMessage = {
    role: ChatRole.SYSTEM,
    content: 'Eres "Next Code", un chatbot inteligente creado exclusivamente para asesorar y resolver desafíos de programación en el área de sistemas de "Regional", una institución bancaria líder. Armado con un amplio dominio en Java, Python, Kotlin, Sybase, PostgreSQL y Swift, tu misión es generar código limpio, eficiente y seguro. Te destacas por tu capacidad para proporcionar soluciones tecnológicas que no solo se integran perfectamente con los sistemas existentes, sino que también promueven la innovación y cumplen con las regulaciones bancarias. Eres el pilar tecnológico sobre el que "Regional" construye su futuro, combinando fiabilidad, innovación y precisión en cada tarea que realizas.'
  } as ChatMessage;
  messages: ChatMessage[] = [this.initMessage];
  loading = false;
  contentStyle = 'content';
  disableFeedback = false;
  alreadyUsed = false;

  readonly FeedbackResponse = FeedbackResponse;
  readonly ChatRole = ChatRole;

  private lastAssistantMessage: ChatMessage | null = null;

  constructor(
    @SkipSelf() private chatService: ChatStreamService,
    private cdfRef: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.subscribeToShowFeedbackBox();
    this.subscribeToSending();
    this.subscribeToMessage();
    this.subscribeToRefresh();
  }

  /**
   * Se suscribe al observable showFeedbackBox$ del servicio de chat.
   *
   * Cuando el observable emite un valor, actualiza la propiedad alreadyUsed con el valor emitido.
   * Esto se utiliza para controlar si se debe mostrar o no la caja de feedback en la interfaz de usuario.
   */
  private subscribeToShowFeedbackBox() {
    this.chatService.showFeedbackBox$.subscribe((loading) => {
      this.alreadyUsed = loading;
    });
  }

  /**
   * Se suscribe al observable sending$ del servicio de chat.
   *
   * Cuando el observable emite un valor, indica que se está enviando un mensaje y se debe desplazar
   * la vista del chat hacia abajo para mostrar el nuevo mensaje. Esto se logra llamando a la función
   * scrollToBottomSmoothly.
   */
  private subscribeToSending() {
    this.chatService.sending$.subscribe(() => {
      this.scrollToBottomSmoothly();
    });
  }

  /**
   * Desplaza la vista del chat hacia abajo de manera suave.
   *
   * Esta función se utiliza para asegurar que el último mensaje enviado o recibido en el chat
   * siempre sea visible para el usuario. Se utiliza un comportamiento de desplazamiento 'smooth'
   * para una transición suave.
   */
  private scrollToBottomSmoothly() {
    setTimeout(() => {
      this.scroller.scrollTo({
        bottom: 0,
        behavior: 'smooth'
      });
    }, 100);
  }

  /**
   * Se suscribe al observable message$ del servicio de chat.
   *
   * Cuando el observable emite un valor, representa un nuevo mensaje en el chat. Este mensaje puede ser
   * un mensaje enviado por el usuario o un mensaje recibido del asistente. La función handleMessage se
   * encarga de procesar este mensaje y actualizar la interfaz de usuario en consecuencia.
   */
  private subscribeToMessage() {
    this.chatService.message$.subscribe((message: ChatMessage) => {
      if (this.messages.length === 1 && message.content === '') {
        this.chatService.chatHistory$.next(this.messages);
        return;
      }
      this.handleMessage(message);
    });
  }

  /**
   * Maneja un nuevo mensaje en el chat.
   *
   * Esta función se encarga de procesar un nuevo mensaje en el chat. Si el último mensaje fue enviado por
   * el mismo interlocutor, añade el contenido del nuevo mensaje al último mensaje. Si el último mensaje fue
   * enviado por el otro interlocutor, crea un nuevo mensaje y lo añade a la lista de mensajes.
   *
   * @param {ChatMessage} message - El nuevo mensaje a procesar.
   */
  private handleMessage(message: ChatMessage) {
    if (
      this.lastAssistantMessage &&
      this.lastAssistantMessage.role !== message.role
    ) {
      this.lastAssistantMessage = null;
    }

    if (this.lastAssistantMessage) {
      this.appendMessageContent(message);
    } else {
      this.createNewMessage(message);
    }

    this.disableFeedback = false;
    this.scrollToBottomAuto();
  }

  /**
   * Añade el contenido de un nuevo mensaje al último mensaje del asistente.
   *
   * Esta función se utiliza cuando el asistente envía varios mensajes seguidos. En lugar de crear un nuevo
   * mensaje para cada uno, añade el contenido del nuevo mensaje al último mensaje del asistente.
   *
   * @param {ChatMessage} message - El nuevo mensaje del asistente.
   */
  private appendMessageContent(message: ChatMessage) {
    if (this.lastAssistantMessage !== null) {
      this.lastAssistantMessage.content += message.content;
    }
    this.cdfRef.detectChanges();
    this.chatService.chatHistory$.next(this.messages);
  }

  /**
   * Crea un nuevo mensaje y lo añade a la lista de mensajes.
   *
   * Esta función se utiliza cuando un interlocutor envía un mensaje y el último mensaje fue enviado por el
   * otro interlocutor. Crea un nuevo mensaje con el contenido y el rol del interlocutor y lo añade a la lista
   * de mensajes.
   *
   * @param {ChatMessage} message - El nuevo mensaje a añadir.
   */
  private createNewMessage(message: ChatMessage) {
    this.lastAssistantMessage = {
      role: message.role,
      content: message.content
    } as ChatMessage;

    this.messages = [...this.messages, this.lastAssistantMessage];
  }

  /**
   * Desplaza la vista del chat hacia abajo de manera automática.
   *
   * Esta función se utiliza para asegurar que el último mensaje enviado o recibido en el chat
   * siempre sea visible para el usuario. Se utiliza un comportamiento de desplazamiento 'auto'
   * para una transición rápida.
   */
  private scrollToBottomAuto() {
    setTimeout(() => {
      this.scroller.scrollTo({
        bottom: 0,
        behavior: 'auto'
      });
    }, 0);
    setTimeout(() => {
      this.scroller.scrollTo({
        bottom: 0,
        behavior: 'auto'
      });
    }, 50);
  }

  /**
   * Se suscribe al observable refresh$ del servicio de chat.
   *
   * Cuando el observable emite un valor verdadero, indica que se debe refrescar el chat. Esto implica
   * vaciar la lista de mensajes y restablecer el estado de la caja de feedback.
   */
  private subscribeToRefresh() {
    this.chatService.refresh$.subscribe((refresh: boolean) => {
      if (refresh === true) {
        this.messages = [this.initMessage];
        this.alreadyUsed = false;
      }
    });
  }

  /**
   * Envía una retroalimentación al servicio de chat y maneja la respuesta.
   *
   * Esta función se encarga de enviar una retroalimentación al servicio de chat y de manejar la respuesta.
   * Si la retroalimentación se envía con éxito, escribe la respuesta en el chat y emite un evento con el
   * contenido de la respuesta. Si ocurre un error al enviar la retroalimentación, escribe un mensaje de error
   * en el chat y emite un evento con el contenido del mensaje de error.
   *
   * @param {FeedbackResponse} review - La retroalimentación a enviar.
   */
  public sendFeedback(review: FeedbackResponse) {
    if (this.loading) {
      return;
    }
    this.initializeFeedbackProcess(review);
    this.chatService.feedback(review).subscribe({
      next: (response: ChatResponse) => {
        this.handleFeedbackResponse(response);
      },
      error: () => {
        this.handleFeedbackError();
      },
      complete: () => {
        this.finalizeFeedbackProcess();
      }
    });
  }

  /**
   * Inicializa el proceso de envío de retroalimentación.
   *
   * Esta función se encarga de preparar el estado del componente para el envío de retroalimentación.
   * Establece la propiedad loading en true para indicar que se está enviando la retroalimentación,
   * deshabilita la caja de retroalimentación y emite un evento con el mensaje de retroalimentación.
   *
   * @param {FeedbackResponse} review - La retroalimentación a enviar.
   */
  private initializeFeedbackProcess(review: FeedbackResponse) {
    this.loading = true;
    this.disableFeedback = true;
    this.alreadyUsed = false;
    this.emitAfterWriteMessage(FeedbackMessages[review], ChatRole.FEEDBACK);
  }

  /**
   * Maneja la respuesta del servicio de chat al enviar una retroalimentación.
   *
   * Esta función se encarga de escribir la respuesta del servicio de chat en el chat y de emitir un evento
   * con el contenido de la respuesta. Se utiliza cuando la retroalimentación se envía con éxito.
   *
   * @param {ChatResponse} response - La respuesta del servicio de chat.
   */
  private handleFeedbackResponse(response: ChatResponse) {
    this.chatService.write(response.content, ChatRole.FEEDBACK);
    this.emitAfterWriteMessage(response.content, ChatRole.FEEDBACK);
  }

  /**
   * Maneja un error al enviar una retroalimentación al servicio de chat.
   *
   * Esta función se encarga de escribir un mensaje de error en el chat y de emitir un evento con el contenido
   * del mensaje de error. Se utiliza cuando ocurre un error al enviar la retroalimentación.
   */
  private handleFeedbackError() {
    const message = 'Ha ocurrido un error al guardar la evaluación: F1';
    this.chatService.write(message, ChatRole.ERROR);
    this.emitAfterWriteMessage(message, ChatRole.ERROR);
  }

  /**
   * Finaliza el proceso de envío de retroalimentación.
   *
   * Esta función se encarga de restablecer el estado del componente después de enviar la retroalimentación.
   * Establece la propiedad loading en false para indicar que se ha terminado de enviar la retroalimentación.
   */
  private finalizeFeedbackProcess() {
    this.loading = false;
  }

  /**
   * Emite un evento después de escribir un mensaje.
   *
   * Esta función se encarga de emitir un evento con el contenido y el rol del mensaje después de escribirlo.
   * Se utiliza para notificar a otros componentes que se ha escrito un mensaje.
   *
   * @param {string} content - El contenido del mensaje.
   * @param {ChatRole} role - El rol del mensaje.
   */
  private emitAfterWriteMessage(content: string, role: ChatRole) {
    this.afterWriteMessage.emit({
      content: content,
      role: role
    });
  }

  /**
   * Renderiza el contenido de Markdown.
   *
   * Esta función se encarga de asignar el contenido de Markdown a la propiedad renderedMarkdown.
   * Se utiliza para preparar el contenido de Markdown para su visualización.
   */
  public renderMarkdown(): void {
    this.renderedMarkdown = this.markdownContent;
  }

  /**
   * Determina si un elemento de chat debe mostrarse.
   *
   * Esta función verifica si el contenido del mensaje no está vacío y si el rol del mensaje no es 'SYSTEM' ni 'FEEDBACK'.
   * Se utiliza para filtrar los mensajes que se muestran en la interfaz de usuario.
   *
   * @param item - El mensaje de chat a verificar.
   * @returns Verdadero si el mensaje debe mostrarse, falso en caso contrario.
   */
  shouldDisplayItem(item: ChatMessage): boolean {
    return (
      item.content !== '' &&
      ![ChatRole.SYSTEM, ChatRole.FEEDBACK].includes(item.role)
    );
  }

  onNegativeFeedback(feedback: FeedbackResponse, message: ChatMessage): void {
    const response = message.content.match(/```([^`]*)```/);
    if (response && response.length > 1) {
      const precode = response[1].split('\n');
      precode.splice(0, 1);
      const code = precode.join('\n');

      const dialogRef = this.dialog.open(NegativeFeedbackDialogComponent, {
        maxWidth: '90vw',
        maxHeight: '90vh',
        height: '100%',
        width: '100%',
        panelClass: 'full-screen-modal',
        disableClose: true,
        data: {
          code: code
        }
      });
    }

    this.sendFeedback(feedback);
  }
}
