import { Injectable } from '@angular/core';
import { ChatStore } from '../../models/chat-store';

@Injectable({
  providedIn: 'root'
})
export class ChatStoreService {
  nextID = '';

  /**
   * Establece el siguiente ID de la conversación.
   *
   * @param {string} nextId - El siguiente ID de la conversación a establecer.
   *
   * Esta función realiza las siguientes operaciones:
   * 1. Prepara el ID de la conversación añadiendo un prefijo 'chat-next-id-' al ID proporcionado.
   * 2. Almacena el ID preparado en la propiedad `nextID`.
   * 3. Imprime el ID almacenado en la consola para fines de depuración.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setNextID(nextId: string) {
    this.nextID = 'chat-next-id-' + nextId;
    console.log('next id');
    console.log(this.nextID);
  }

  /**
   * Almacena la información de la conversación en el almacenamiento local.
   *
   * @param {ChatStore} store - El objeto que contiene la información de la conversación a almacenar.
   *
   * Esta función realiza las siguientes operaciones:
   * 1. Convierte el objeto `store` a una cadena JSON.
   * 2. Almacena la cadena JSON en el almacenamiento local, utilizando `this.nextID` como la clave.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set(store: ChatStore) {
    const data = JSON.stringify(store);
    localStorage.setItem(this.nextID, data);
  }

  /**
   * Obtiene la información de la conversación del almacenamiento local.
   *
   * Esta función realiza las siguientes operaciones:
   * 1. Intenta recuperar la información de la conversación del almacenamiento local utilizando `this.nextID` como la clave.
   * 2. Si se encuentra información de la conversación, intenta convertirla de una cadena JSON a un objeto y la devuelve.
   * 3. Si no se encuentra información de la conversación, o si la información encontrada no es un JSON válido, devuelve `undefined`.
   *
   * @returns {ChatStore | undefined} El objeto que contiene la información de la conversación, o `undefined` si no se encuentra ninguna información o si la información encontrada no es un JSON válido.
   */
  get(): ChatStore | undefined {
    console.log('get next id');
    console.log(this.nextID);
    const data = localStorage.getItem(this.nextID) || '';
    if (data !== '') {
      try {
        return JSON.parse(data);
      } catch (e) {
        return;
      }
    }
    // eslint-disable-next-line sonarjs/no-redundant-jump
    return;
  }
}
