import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { ConversacionDto } from 'src/app/models/conversacion-dto';
import { ChatAppService } from 'src/app/services/chat-app/chat-app.service';
import { ChatStreamService } from 'src/app/services/chat/chat-stream.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Output() eventNewConversacion = new EventEmitter();
  @Output() eventLoadConversacion: EventEmitter<string> =
    new EventEmitter<string>();

  typesOfShoes: string[] = [
    'Boots',
    'Clogs',
    'Loafers',
    'Moccasins',
    'Sneakers'
  ];

  conversaciones: ConversacionDto[] = [];
  ocultar = true;

  newConversacion() {
    debugger;
    this.cargarConversaciones();
    this.eventNewConversacion.emit();
  }

  LoadConversacion(id_conversacion: string) {
    this.eventLoadConversacion.emit(id_conversacion);
  }

  minHeight = '50px';
  maxHeight = '50px';

  @HostListener('window:resize')
  onWindowResize() {
    this.minHeight = window.innerHeight + 'px';
    this.maxHeight = window.innerHeight + 'px';
  }

  constructor(
    private chatService: ChatStreamService,
    private chatAppService: ChatAppService
  ) {
    this.onWindowResize(); // Establecer el tamaño inicial del sidebar al cargar el componente
    this.cargarConversaciones();
  }

  cargarConversaciones() {
    const userid = this.chatAppService.getIdEquipoCliente();
    this.conversaciones = [
      {
        fecha_creacion: '2023-10-04',
        id_user: 1,
        id_conversacion: '1234',
        titulo: 'Alexis Test',
        fecha_actualizacion: '2023-10-05',
        activo: true
      },
      {
        fecha_creacion: '2023-10-06',
        id_user: 1,
        id_conversacion: '1234',
        titulo: 'Alexis Test 2',
        fecha_actualizacion: '2023-10-06',
        activo: false
      }
    ];
    /*this.chatService.getConversaciones(userid).subscribe((items) => {
      console.log(items);
      this.conversaciones = items;
    });*/
  }

  ocultarSidebar(forzar: boolean): void {
    this.ocultar = forzar ? false : !this.ocultar;
  }
}
