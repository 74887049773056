<mat-sidenav-container class="example-container">
  <mat-sidenav
    #sidenav
    mode="side"
    [(opened)]="opened"
    (opened)="events.push('open!')"
    (closed)="events.push('close!')"
  >
    <button
      mat-icon-button
      aria-label="Conversación"
      class="corner-button"
      (click)="onButtonClick()"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <app-sidebar
      (eventNewConversacion)="newConversacion()"
      (eventLoadConversacion)="loadConversacion()"
    ></app-sidebar>
  </mat-sidenav>

  <mat-sidenav-content>
    <div id="sidenav-main">
      <app-main (sidebarToggle)="onButtonClick()" [sidebarClose]="isActive"></app-main>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
