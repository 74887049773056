import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidebarComponent } from '../views/sidebar/sidebar.component';
import { ChatStreamService } from 'src/app/services/chat/chat-stream.service';
import { Message } from 'src/app/models/message';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav = {} as MatSidenav;
  @ViewChild(SidebarComponent) sidebarComponent: SidebarComponent | undefined;
  reason = '';
  user_id = '';
  uToken = '';

  events: string[] = [];
  opened: boolean | undefined;
  isActive = false;

  constructor(private chatService: ChatStreamService) {}

  ngOnInit(): void {
    console.log('ChatComponent');
  }

  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
  }

  newConversacion() {
    console.log('newConversacion');
  }

  async loadConversacion(): Promise<void> {
    await this.obtenerHistorial();
  }

  async obtenerHistorial(): Promise<void> {
    try {
      const data: Message[] = this.chatService.getConversaciones();
      console.log(data);
      data.forEach((element) => {
        console.log(element);
      });
    } catch (error) {
      console.error(error);
    }
  }

  onButtonClick() {
    this.sidenav.toggle();
    this.isActive = this.sidenav.opened;
  }
}
