import {
  Component,
  EventEmitter,
  Input,
  Output,
  SkipSelf,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { ChatRole } from 'src/app/enums/chat-role';
import { FeedbackResponse } from 'src/app/enums/feedback-response';
import { ChatApp } from 'src/app/models/chat-app';
import { ChatMessage } from 'src/app/models/chat-message';
import { ChatStreamService } from 'src/app/services/chat/chat-stream.service';
import { MatDialog } from '@angular/material/dialog';
import { CodeDialogComponent } from '../dialogs/code-dialog/code-dialog.component';
import { CodeData } from '../../../models/code-data';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements AfterViewInit {
  @Input() appConfig!: ChatApp;
  @Output() afterWriteMessage: EventEmitter<ChatMessage> =
    new EventEmitter<ChatMessage>();
  @ViewChild('messageBox') messageBox!: ElementRef;
  message = '';
  loading = false;
  originalHeight = '';

  currentCodeAdded: CodeData = {
    code: '',
    language: ''
  };

  readonly FeedbackResponse = FeedbackResponse;

  get codeBadgeHidden() {
    return !this.codeBadge;
  }

  get codeBadge() {
    return this.currentCodeAdded.code.length > 0 ? 1 : 0;
  }

  constructor(
    @SkipSelf() private chatService: ChatStreamService,
    private dialog: MatDialog
  ) {
    this.chatService.response$.subscribe((text) => {
      if (text.content !== '') {
        this.chatService.write(text.content, ChatRole.ASSISTANT);
      }
    });
    this.chatService.loading$.subscribe((loading) => (this.loading = loading));
  }

  ngAfterViewInit() {
    this.messageBox.nativeElement.addEventListener(
      'input',
      this.autoResize,
      false
    );
    this.originalHeight = this.messageBox.nativeElement.offsetHeight + 'px';
  }

  autoResize(event: Event) {
    // eslint-disable-next-line xss/no-mixed-html
    const targetTextarea: HTMLTextAreaElement =
      event.target as HTMLTextAreaElement;
    targetTextarea.style.height = 'auto';
    targetTextarea.style.height = targetTextarea.scrollHeight + 'px';
  }

  sendMessage(event: Event | MouseEvent): void {
    if (this.loading || !this.message) {
      return;
    }
    this.messageBox.nativeElement.style.height = this.originalHeight;
    event.preventDefault();
    this.loading = true;

    let messageToSend = this.message.trim();

    if (this.currentCodeAdded.code.length > 0) {
      messageToSend = `${this.message.trim()} \n \`\`\`${this.currentCodeAdded.language} \n ${this.currentCodeAdded.code} \n \`\`\``;
    }
    this.message = '';
    this.currentCodeAdded = {
      language: '',
      code: ''
    };
    this.afterWriteMessage.emit({
      role: ChatRole.USER,
      content: this.message
    });
    this.chatService.send(messageToSend).subscribe({
      next: (response) => {
        this.chatService.write(response.content, ChatRole.ASSISTANT);
      },
      error: (error) => {
        const errorMessage = 'Ha ocurrido un error inesperado';
        console.error(error.detail);
        this.chatService.write(errorMessage, ChatRole.ERROR);
      },
      complete: () => {
        console.log('La operación se ha completado');
      }
    });
  }

  isSendButtonDisabled(): boolean {
    // Aquí va tu lógica para determinar si el botón debe estar deshabilitado o no.
    // Por ahora, solo devolveremos false para que el botón no esté deshabilitado.
    return !this.message;
  }

  openAddCodeDialog(): void {
    const dialogRef = this.dialog.open(CodeDialogComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      disableClose: true,
      data: this.currentCodeAdded
    });

    dialogRef.componentInstance.code.subscribe((code) => {
      this.currentCodeAdded = code;
    });
  }
}
